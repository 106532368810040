<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <div  class="wrap clientindex">
          <h1>Индекс клиентоцентричности</h1>
          <RegionAndDepartmentPicker v-model="$regionAndDepartmentInfo" :nullable-department="nullableDepartment"></RegionAndDepartmentPicker>

          <v-select style="width:400px; margin-top: -40px;"
                    :items="periods"
                    v-model="period"
                    label="Период мониторинга"
          ></v-select>

          <v-tabs v-model="activeTab">
            <v-tab>Индекс Клиентоцентричности</v-tab>
            <v-tab v-if="$regionAndDepartmentInfo?.department?.type !== 'manager_czn'">Субъективные данные</v-tab>
            <v-tab>Объективные данные</v-tab>


            <v-tab-item>
              <ClientCentricityMain :period="period" :checklist-domain="domain" :period-span="periodSpan" :periods="periods"></ClientCentricityMain>
            </v-tab-item>

            <v-tab-item v-if="$regionAndDepartmentInfo?.department?.type !== 'manager_czn'">
              <ClientCentricityAnket :period="period"></ClientCentricityAnket>
            </v-tab-item>

            <v-tab-item>
              <ClientCentricityCheckList
                  v-if="$regionAndDepartmentInfo.department != null"
                  :domain="domain"
                  :period="period"
                  :period-span="periodSpan"
              ></ClientCentricityCheckList>
              <div v-else>Для просмотра и заполнения объективных данных выберите ЦЗН</div>
            </v-tab-item>
          </v-tabs>
      </div>
    </transition>
  </div>
</template>

<script>

import ClientCentricityAnket from "@/components/icc_new/ClientCentricityAnket";
import ClientCentricityCheckList from "@/components/icc_new/ClientCentricityCheckList";
import ClientCentricityMain from "@/components/icc_new/ClientCentricityMain";
import RegionAndDepartmentPicker from "@/components/RegionAndDepartmentPicker";
import {generatePeriodsBetween, getPeriodByDate} from "@/modules/periods";


export default {
  name: 'ClientIndexComponent',
  components: {
    RegionAndDepartmentPicker,
    ClientCentricityMain,
    ClientCentricityCheckList,
    ClientCentricityAnket,
  },

  data: () => ({
    domain: 'client_centricity', // домен в едином хранилище показателей
    periodSpan: 'QUARTER', // как устроены периоды для показателей
    period: null, // текущий выбранный период
    minPeriod: '2023_H2_Q3', // с какого периода начинается заполнение
    maxPeriod: null, // максимальный период (предполагается предшествующий текущему)
    periods: [],
    activeTab: 0
  }),

  methods: {
  },
  computed: {
    nullableDepartment(){
      return this.activeTab===0
    }
  },
  async beforeMount() {
    this.activeTab = Number(this.$route.query.tab) || 0
    this.maxPeriod = getPeriodByDate(new Date(), this.periodSpan)
    this.maxPeriod = "2024_H2_Q3" 
    this.period = this.maxPeriod
    this.periods = generatePeriodsBetween(this.maxPeriod, this.minPeriod, this.periodSpan)
  },
  watch: {
    $regionAndDepartmentInfo: {
      async handler() {
        this.$forceUpdate()
      },
      deep: true
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/main";

.top-progress {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;
    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }
    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}



.clientindex {
  div.blue {
  background: #E1F0FA !important;
  color: #2F80ED !important;
}
div.green {
  background: #E2F5EA !important;
  color: #27AE60 !important;
}
div.yellow {
  background: #FCF4DB !important;
  color: #F2994A !important;
}
dv.darkblue {
  background: #E5EBF5 !important;
  color: #0033A0 !important;
}
.badge {
  height: 30px;
  margin-left: 4px;
  margin-right: 0;
  font-size: 13px;
  font-weight: 700;
}


  .v-slide-group__content.v-tabs-bar__content {
    justify-content: space-around !important;
  }
  .v-window-item {
    padding-top: 50px;
  }
  .v-expansion-panel .level2 {
    padding-top: 5px;
    .v-expansion-panel-content {
      padding-left: 44px;
    }
  }

  .v-expansion-panel-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    margin: 10px 0;
  }
  .list .list-item-wrap .list-item-name {
    max-width: none;
    .toggleSwitch {
      align-self: center;
    }
  }
  .list .list-item-wrap .list-item {
    justify-content: flex-start;
    .v-progress-circular {
      margin-left: 0;
            .v-progress-circular__info {
    font-size: 16px;
    font-weight: 600;
    display: block;
    .text-caption {
      font-size: 10px !important;
      vertical-align: baseline;
      display: inline;
    }
  }
    }
  }
  .list-item-wrap .list-item-wrap {
    border: none;
    padding-bottom: 5px;
  }
}

.graph-wrap {
 background: #F3F3F3;
border-radius: 20px;
  padding: 30px 30px 0 30px;
  h3 {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    text-align: center;
    margin-bottom: 10px;
  }
  .graph-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 10px;
    div:first-child {
      font-size: 36px;
      line-height: 30px;
      span {
        font-size: 12px;
        vertical-align: text-top;
      }
    }
    div:last-child {
      font-weight: 600;
      font-size: 16px;
      color: #1C1C1F;
    }
    &.text-blue {
      color: #2F80ED;
      div:last-child span {
        color: #2F80ED;
      }
    }
    &.text-orange {
      color: #F2994A;
      div:last-child span {
        color: #F2994A;
      }
    }

  }
  &.checklist-wrap {
    padding: 20px;
    display: flex;
    gap: 30px;
    background: #F3F3F3;
    border-radius: 20px;
    min-height: 130px;
    > div:first-child {
      width: 140px;
      div {
        color: #2F80ED;
        font-size: 36px;
        line-height: 30px;
        font-weight: 600;
        span {
          vertical-align: text-top;
          font-size: 12px;
          color: #2F80ED;
        }
      }
    }
    .checklist > div {
      display: flex;
      margin-bottom: 10px;
      font-weight: 500;
font-size: 14px;
line-height: 160%;
       gap: 10px;
      span {
        width: 14px;
      }
      svg {
        margin-top: 4px;
      }
      &.checked {
        color: #0033A0;
      }
    }
          .v-progress-circular__info {
    font-size: 16px;
    display: block;
    font-weight: 600;
    .text-caption {
      font-size: 7px !important;
      vertical-align: baseline;
    }
  }
  }
}

.line-wrap {
  border: 1px solid #E8E8E9;
border-radius: 4px;
}

</style>
